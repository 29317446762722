import { useState,Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import "./header.css";
import { XMarkIcon } from '@heroicons/react/24/outline'

import logo from "../../assets/icons/logo.jpg";
import pandaAvatar from "../../assets/icons/panda-avatar-shadow.svg";


function Header() {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);


    return ( 
        <div className="w-full landing-header">
            <div className="flex flex-row gap-8 lg:gap-12 py-2 px-4 w-full max-w-[1440px] mx-auto justify-between">
      
                <a href="/" className="flex flex-row gap-2 items-center py-2 md:py-4">
                    <img src={logo} alt="pandarative logo" className="h-8 md:12 lg:h-16"></img>
                    <span className="hidden md:block response-font-size-content font-title font-bold">Pandarrativ</span>
                </a>
       

                <button className="p-1 rounded-sm hover:bg-gray-400 lg:hidden block" onClick={() => setShowMenu(!showMenu)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"  className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>

                <div className="flex-grow hidden lg:flex lg:gap-8 flex-row items-center justify-end  font-semibold font-title response-font-size-content">                    
                    <a href="/products">
                        Our Products
                    </a>
                    <a  href="/materials">
                        Materials
                    </a>
                    <a  href="/social-story">
                        Social Story
                    </a>
                    {/* <a href="/philosophy" className="text-h5 font-semibold font-title">
                        Philosophy
                    </a> */}
                    <a href="/support">
                        Support
                    </a>
                    {/* <a href="/about-us">
                        About Us
                    </a> */}


                    <div className="flex flex-row gap-2 items-center">
                        <button className="text-h5 font-semibold sign-up-btn border-2 border-bcolor1 font-title" onClick={() => navigate("/login")}>Sign In</button>
                        <a href="/">
                            <img src={pandaAvatar} alt="a panda logo" className="h-20 cursor-pointer"></img>
                        </a>
                    </div>
                </div>


                {/* moble menu */}
                <Transition.Root show={showMenu} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden block" onClose={setShowMenu}>
                        <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                            <div className="flex items-center justify-between px-4">
                                <a href="/" className="text-lg font-bold hover:cursor-pointer text-gray-900 font-title">Pandarrativ</a>
                                <button
                                type="button"
                                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                onClick={() => setShowMenu(false)}
                                >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="false" />
                                </button>
                            </div>

                            <div className="mt-4 border-t border-gray-200">
                                <div className="px-4 py-2 sm:py-4 font-medium text-gray-900 landing-menu-items font-title">
                                    <a href="/products">Products</a>
                                </div>
                                <div className="px-4 py-2 sm:py-4 font-medium text-gray-900 landing-menu-items font-title">
                                    <a href="/materials">Materials</a>
                                </div>
                                <div className="px-4 py-2 sm:py-4 font-medium text-gray-900 landing-menu-items font-title">
                                    <a href="/social-story">Social Story</a>
                                </div>
                                <div className="px-4 py-2 sm:py-4 font-medium text-gray-900 landing-menu-items font-title">
                                    <a href="/support">Support</a>
                                </div> 
                                {/* <div className="px-4 py-2 sm:py-4 font-medium text-gray-900 landing-menu-items font-title">
                                    <a href="/about-us">About Us</a>
                                </div> */}
                            </div>

                            <div className="mt-auto flex flex-col w-full items-center px-4 gap-4 border-t border-gray-200 pt-8">
                                <a href="/">
                                    <img src={pandaAvatar} alt="a panda logo" className="h-24 cursor-pointer"></img>
                                </a>
                                <button className="text-h5 font-semibold sign-up-btn border-2 border-bcolor1 font-title" onClick={() => navigate("/login")}>Sign In</button>
                            </div>
         
 
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </div>
     );
}

export default Header;