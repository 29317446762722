import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function SafetyPage() {
    return ( 
        <div className="safety-page font-content response-font-size-content">
            <Header/>


            {/* section 1 */}
            <div className="w-full bg-landing-1">
                <div className="w-full max-w-[1440px] mx-auto flex flex-col gap-2 sm:gap-4 md:gap-8 pt-8 response-page-padding-x-lg">
                        <div className="w-full flex gap-8 items-center">
                            <span className="flex-grow h-0 border-t-2 border-gray-400 rounded-sm"></span>
                            <div className="text-center font-title font-semibold response-font-size-title1">Digital Safety</div>
                            <span className="flex-grow h-0 border-t-2 border-gray-400 rounded-sm"></span>
                        </div>

                        {/* 1 */}
                        {/* header style */}
                        <div className="w-full text-center pt-12 response-font-size-title2 font-title font-semibold">Safety is Always the First</div>
                        {/* content */}
                        <div className="w-full mt-4">
                        Just as consumers choose infant formula based not on taste but on safety first, we firmly believe that all children's learning products need to prioritize safety. Therefore, all content we deliver to users has been reviewed by us. We have developed our own real-time safety review system specifically for AI-generated content that involves real-time interaction. And for the pre-set the materials, we although we use the AI for first round review, we still involve experts for the final review. 
                        </div>

                        {/* 2 */}
                        {/* header style */}
                        <div className="w-full text-center pt-12 response-font-size-title2 font-title font-semibold">Adaptation of the Consensus Game for Educational Content</div>
                        {/* content */}
                        <div className="w-full mt-4">
                            <span className="font-semibold">Generator (G):</span> Adapt the Generator to produce educational narratives based on input topics or questions. The Generator should be capable of generating multiple narratives or answers that cover the scope of the input query comprehensively.
                        </div>
                        <div className="w-full mt-4">
                            <span className="font-semibold">Discriminator (D):</span> The Discriminator should be tasked with evaluating the educational content generated by G. Its role is to assess the content against the established safety criteria, focusing on accuracy, relevance, and adherence to educational standards.
                        </div>


                        {/* 3 */}
                        {/* header style */}
                        <div className="w-full text-center pt-12 response-font-size-title2 font-title font-semibold">Regularized Imperfect-Information Sequential Signaling Game</div>
                        {/* content */}
                        <div className="w-full mt-4">
                            <span className="font-semibold">Game Setup:</span>As in the original paper, cast the decoding of educational content as a game where G aims to communicate a "correctness parameter" (in this context, safety and accuracy) to D using natural language. D then predicts this parameter based on the content.
                        </div>
                        <div className="w-full mt-4">
                            <span className="font-semibold">Equilibrium Search:</span> Utilize computational procedures for finding approximate equilibria of this game. The equilibrium strategies ensure that both G and D agree on the safety and accuracy of the generated content.
                        </div>

                        {/* 4 */}
                        {/* header style */}
                        <div className="w-full text-center pt-12 response-font-size-title2 font-title font-semibold">Content Evaluation and Refinement Loop</div>
                        {/* content */}
                        <div className="w-full mt-4">
                            Implement a loop where the educational content generated by G is continually refined based on feedback from D. This iterative process aims to reach a consensus on the content's safety and educational value.
                        </div>
                        <div className="w-full mt-4">
                            <span className="font-semibold">Iterative Improvement: </span> Through no-regret learning algorithms, both G and D refine their strategies over multiple iterations, leading to improved content generation and evaluation.
                        </div>

                        {/* 5 */}
                        {/* header style */}
                        <div className="w-full text-center pt-12 response-font-size-title2 font-title font-semibold">Human-in-the-Loop for Final Verification</div>
                        {/* content */}
                        <div className="w-full mt-4">
                            Incorporate a human-in-the-loop mechanism where selected narratives are reviewed by educators or subject matter experts. This step ensures that the AI-generated content meets high educational standards and addresses any nuanced issues that might be difficult for AI models to discern.
                        </div>

                        {/* 6 */}
                        {/* header style */}
                        <div className="w-full text-center pt-12 response-font-size-title2 font-title font-semibold">Deployment and Continuous Learning</div>
                        {/* content */}
                        <div className="w-full mt-4">
                        Deploy the system to generate and review educational content, with continuous monitoring and updating of the models based on real-world usage and feedback.
                        </div>
                        <div className="w-full mt-4">
                            <span className="font-semibold">Feedback Loop:  </span> Collect data on the performance and acceptance of the generated content to further train and refine G and D, ensuring the system evolves and remains effective over time.
                        </div>
                </div>
            </div>




            <Footer/>
        </div>
     );
}

export default SafetyPage;