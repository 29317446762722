
import { useEffect, useState } from 'react';
import axios from 'axios';
import { oauthSessionInfoRouter } from '../../configs/URL';
import bg6 from "../../assets/imgs/bg-6.png";
import { useLocation } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import { localParamMap } from '../../configs/localParamMap';

function CredentialFetchPage() {
    const location = useLocation();
    const navigator = useNavigate("/");
    const [message, setMessage] = useState("Loading");


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const locParam = queryParams.get('location');


        axios.get(oauthSessionInfoRouter)
        .then((resp) => {
            const userData = resp.data;

            // first set userData into sessiondata
            sessionStorage.setItem("pandarrativ-user", JSON.stringify(userData));
            
            if(!userData.role){
                navigator(`/role-selection?location=${locParam}`);
            }else{
                window.location.href = localParamMap[locParam] || 'http://pandarrativ.com';
            }
        })
        .catch(error => {
            console.error('Error fetching user data:', error);
            setMessage("Error fetching user data...");
            setTimeout(() => {
                navigator("/login")
            }, 500);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='role-selection w-full relative h-screen min-h-[608px] font-content flex items-center justify-center'>
            <div className="absolute z-0 w-full h-full img-box">
                <img src={bg6} alt="pandas are looking into the sky" className="box-img"></img>
            </div>
            
            <div className='absolute text-h4 font-semibold z-1'>{message}</div>
        </div>
    );
}

export default CredentialFetchPage;