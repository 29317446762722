import "./role-selectionpage.css";
import bg6 from "../../assets/imgs/bg-6.png"; // temp
import roleStudent from "../../assets/imgs/role-select-student.png";
import roleParent from "../../assets/imgs/role-select-parent.png";
import roleTeacher from "../../assets/imgs/role-select-teacher.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from 'react-router-dom'; 
import { setRoleRouter } from "../../configs/URL";
import { localParamMap } from '../../configs/localParamMap';


function RoleSelectionPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState("");
    const [userProfile, setUserProfile] = useState();

    useEffect(() => {
        const userInfo = JSON.parse(sessionStorage.getItem("pandarrativ-user"));
        console.log(userInfo);
        if(!userInfo){
            navigate("/login");
        }else{
            setUserProfile(userInfo);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const toggleSelectedRole = (role) => {
        if(role === selectedRole){
            setSelectedRole("")
        }else{
            setSelectedRole(role)
        }
    }

    const setUserRole = () => {
        const queryParams = new URLSearchParams(location.search);
        const locParam = queryParams.get('location') || "/";



        if(!selectedRole){
            document.getElementById('role-selection_modal').showModal(); 
        }else{
            axios.post(setRoleRouter, {
                _id: userProfile._id,
                role: selectedRole,
            })
            .then((resp) => {
                const userInfo = resp.data;
                localStorage.setItem("pandarrativ-user", JSON.stringify(userInfo));
                // navigate(locParam);
                window.location.href = localParamMap[locParam] || 'http://pandarrativ.com';
            })
            .catch((e) => {
                console.log(e.response.data.message);
            })
        }
    }

    return ( 
        <div className="role-selection w-full relative h-screen min-h-[608px] font-content">
            <div className="absolute z-0 w-full h-full img-box">
                <img src={bg6} alt="pandas are looking into the sky" className="box-img"></img>
            </div>

            <div className="absolute left-0 top-0 z-1 w-full h-full flex flex-col gap-2 items-center justify-center">
                <div className="flex flex-row justify-around w-full max-w-[1440px]">
                    <div className={`role-card role-selected-${selectedRole === "parent"} flex flex-col gap-2 w-3/12 items-center justify-center`} onClick={() => toggleSelectedRole("parent")}>
                        <div className="font-title font-semibold text-hint1 sm:text-h5 md:text-h4 lg:text-h3">Parents</div>
                        <img src={roleParent} alt="a parent panda" ></img>
                    </div>
                    <div className={`role-card role-selected-${selectedRole === "educator"} flex flex-col gap-2 w-3/12 items-center justify-center`} onClick={() => toggleSelectedRole("educator")}>
                        <div className="font-title font-semibold text-hint1 sm:text-h5 md:text-h4 lg:text-h3">Educators</div>
                        <img src={roleTeacher} alt="a teacher panda"></img>
                    </div>
                    <div className={`role-card role-selected-${selectedRole === "student"} flex flex-col gap-2 w-3/12 items-center justify-center`} onClick={() => toggleSelectedRole("student")}>
                        <div className="font-title font-semibold text-hint1 sm:text-h5 md:text-h4 lg:text-h3">Students</div>
                        <img src={roleStudent} alt="a student panda" ></img>
                    </div>
                </div>

                <button className="btn-style-1 text-hint2 sm:text-h6 md:text-h5 lg:text-h4 signup-height" id="role-selection-btn" onClick={setUserRole}>Confirm</button>
            </div>


            <dialog id="role-selection_modal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Oop!</h3>
                    <p className="py-4">Please pick your role to continue!</p>
                    <div className="modal-action">
                    <form method="dialog">
                        <button className="btn">Close</button>
                    </form>
                    </div>
                </div>
            </dialog>
        </div>
     );
}

export default RoleSelectionPage;