
import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./social-story-page.css";
// import {
//   useNavigate,
// } from 'react-router-dom';

function SocialStoryPage() {
    // const navigate = useNavigate();
  
  // React.useEffect(() => {
  //   // Redirect to the PDF file
  //   window.location.href = '/Poster.pdf';
  // }, [navigate]);

  // return null; // No UI is rendered
  return (
    <div className="materials-page font-content">
    {/* header */}
    <Header/>

    {/* section 1 */}

    {/* <PDFViewer pdfURL="/case_cooperation.png"></PDFViewer> */}
    {/* <div className="w-full bg-landing-section-2">
            <div className="w-full max-w-[1440px] mx-auto p-4 md:p-16 lg:px-32">

                <div className="container">
                  <iframe 
                    src="/Poster.pdf" 
                    className="iframe"
                    frameBorder="0"
                    title="PDF Viewer"
                  />
                </div>

            </div>
        </div>
 */}

      <div className="w-full bg-landing-section-2">
                <div className="w-full max-w-[1440px] mx-auto p-4 md:p-16 lg:px-32">
                    <img src={`Poster.png`} alt="example" class="w-[70%] mx-auto"></img>
                </div>
            </div>




    {/* footer */}
    <Footer/>
</div>
  );
}

export default SocialStoryPage;