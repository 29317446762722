import "./footer.css";

import iconX from "../../assets/icons/twitterx.svg";
import iconFacebook from "../../assets/icons/facebook.svg";
import iconIns from "../../assets/icons/instagram.svg";
import iconHeart from "../../assets/icons/heart.svg";

function Footer() {
    return ( 
        <div className="landing-footer w-full py-4 px-4 sm:px-8 font-content">
            <div className="flex flex-col justify-around items-center w-full max-w-[1440px] mx-auto gap-4">
                <div className="flex flex-col gap-2 items-center">
                    <div className="landing-footer-title text-hint1 sm:text-h5 md:text-h4 lg:text-h3 font-semibold font-title">Pandarrativ</div>
                    <div className="font-bold text-hint2 sm:text-hint1 md:text-h5">Subscribe</div>
                    <div className="text-hint3 sm:text-hint2 md:text-hint1 lg:text-h5">Stay connected to our latest news!</div>
                    <div className="flex flex-col sm:flex-row gap-4 items-end">
                        <label className="input input-bordered footer-intput-label flex items-center gap-2 ">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                            <input type="text" className="grow" placeholder="Email" />
                        </label>
                        <button className="btn w-32 sm:w-auto footer-submit-btn">Submit</button>
                    </div>
                </div>


                <div className="flex flex-row gap-16 justify-between sm:px-4 md:px-16 items-center w-full lg:px-32">
                    <div className="landing-footer-privacy w-full sm:hidden flex flex-row lg:gap-8 justify-between text-hint3 sm:text-hint2 md:text-hint1 lg:text-h5">
                        <div className="font-semibold">Contact us</div>
                        <div className="font-semibold">FAQ</div>
                        <a href="/privacy" className="font-semibold">Privacy Policy</a>
                        <a href="/terms" className="font-semibold">Terms of Use</a>
                    </div>

                    <div className="hidden landing-footer-privacy sm:flex flex-row lg:gap-8 gap-6 text-hint3 sm:text-hint2 md:text-hint1 lg:text-h5">
                        <div className="font-semibold">Contact us</div>
                        <div className="font-semibold">FAQ</div>
                        <a href="/privacy" className="font-semibold">Privacy Policy</a>
                        <a href="/terms" className="font-semibold">Terms of Use</a>
                    </div>

                    <div className="hidden sm:flex flex-row gap-4">
                        <div><img src={iconX} alt="subscribe to us on twitter" className="w-8 md:w-12 h-8 md:h-12 "></img> </div>
                        <div><img src={iconFacebook} alt="subscribe to us on facebook"  className="w-8 md:w-12 h-8 md:h-12"></img> </div>
                        <div><img src={iconIns} alt="subscribe to us on instagram" className="w-8 md:w-12 h-8 md:h-12"></img> </div>
                    </div>

                </div>

                <div className="flex flex-row justify-between w-full sm:px-4 md:px-16 lg:px-32">
                    <div className="text-hint3 sm:text-hint2 md:text-hint1">©Pandarrativ</div>
                    <div className="text-hint3 sm:text-hint2 md:text-hint1 flex flex-row gap-2"><img src={iconHeart} alt="a warning heart of pandarrative!" className="w-4"></img> Let's begin journey with Pandarrativ!</div>
                </div>
            </div>
        </div>
     );
}

export default Footer;