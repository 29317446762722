import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function TermsPage() {
    return ( 
        <div className="terms-page font-content response-font-size-content">

        </div>
     );
}

export default TermsPage;