import "./login-page.css";
import axios from "axios";
import bg5 from "../../assets/imgs/bg-5.png"; // temp
import pandaAvatarShadow from "../../assets/icons/panda-avatar-shadow.svg"
import iconGoogle from "../../assets/icons/google.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authGoogleRedirectRouter, getSignInDestRouter, getSignUpDestRouter } from "../../configs/URL";


function LoginPage() {
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(true);
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [signUpUsername, setSignUpUsername] = useState("")
    const [signUpPassword, setSignUpPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const toggleLogin = (e) => {
        e.preventDefault();
        setErrorMessage("");
        setShowLogin(!showLogin);
    }


    const login = (e) => { 
        e.preventDefault();
        setErrorMessage("");

        const queryParams = new URLSearchParams(window.location.search);
        const dest = queryParams.get('dest') || "main";   


        axios.post(getSignInDestRouter(dest), {
            "email": username,
            "password": password,
        })
        .then((resp) => {
            navigate(resp.data.redirectURL)
        })
        .catch((e) => {
            // errors
            console.log(e);
            setErrorMessage(e.response.data.message);
        })
    }

    const signup = (e) => {
        e.preventDefault();
        setErrorMessage("");

        const queryParams = new URLSearchParams(window.location.search);
        const dest = queryParams.get('dest') || "main"; 

        
        axios.post(getSignUpDestRouter(dest), {
            "email": signUpUsername,
            "password": signUpPassword,
            "confirmPassword": confirmPassword,
        })
        .then((resp) => {
            navigate(resp.data.redirectURL);
        })
        .catch((e) => {
            // errors
            console.log(e);
            setErrorMessage(e.response.data.message);
        })
    }


    const loginGoogle = (e) => {
        // get destination in url
        const queryParams = new URLSearchParams(window.location.search);
        const dest = queryParams.get('dest') || "main";   
        const authAndRedirectRouter = authGoogleRedirectRouter(dest);
        console.log(authAndRedirectRouter)

        window.location.href = authAndRedirectRouter;
    }


    const renderSignup = () => {
        return (
            <div className="absolute flex flex-col w-64 sm:w-80 md:w-96 signup z-1 items-center gap-2 sm:gap-6 pb-4">
            
                <div className="font-title text-hint2 sm:text-hint1 md:text-h5 lg:text-h4 font-semibold mt-[1rem]">Sign up</div>
                
                {errorMessage && <div className="font-content response-font-size-content text-red-700">{errorMessage}</div>}

                {/* login/sign up form */}
                <form className="flex flex-col gap-4 w-5/6">
                    <label className="input input-bordered flex items-center gap-2 rounded-2xl signup-height">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" /></svg>
                        <input type="text" className="grow" placeholder="Username or Email"  value={signUpUsername} onChange={(e) => setSignUpUsername(e.target.value)}/>
                    </label>
                    <label className="input input-bordered flex items-center gap-2 rounded-2xl signup-height">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
                        <input type="password" className="grow" placeholder="Password"  value={signUpPassword} onChange={(e) => setSignUpPassword(e.target.value)}/>
                    </label>
                    <label className="input input-bordered flex items-center gap-2 rounded-2xl signup-height">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
                        <input type="password" className="grow" placeholder="Confirm Password"  value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </label>

                    <button className="btn-style-1 text-hint2 sm:text-hint1 md:text-h5 lg:text-h4 signup-height" onClick={signup}>Sign up</button>
                    <button className="text-hint2 sm:text-hint1 md:text-h5 text-center text-purple-900" onClick={toggleLogin}>Already has an account? <span className="font-semibold">Log in</span></button>
                </form>
                

                {/* sign in with google */}
                <div className="input input-bordered flex items-center gap-2 rounded-2xl w-5/6 signup-height">
                    <img src={iconGoogle} alt="log in with goole" className="h-4/6"></img>
                    <button className="grow text-hint2 sm:text-hint1 md:text-h5" onClick={loginGoogle}>Sign in with Google</button>
                </div>
                
            </div>
        );
    };

    const renderSignIn = () => {
        return (
            <div className="absolute flex flex-col w-64 sm:w-80 md:w-96 signup z-1 items-center gap-2 sm:gap-6 pb-4">
                <img src={pandaAvatarShadow} alt="avatar of pandara" className="w-2/6 aspect-square mt-[-3rem]"></img>
                {/* header */}
    
                <div className="font-title text-hint2 sm:text-hint1 md:text-h5 lg:text-h4 font-semibold mt-[-1rem]">Sign in</div>

                {errorMessage && <div className="font-content response-font-size-content text-red-700">{errorMessage}</div>}


                {/* login/sign up form */}
                <form className="flex flex-col gap-4 w-5/6">
                    <label className="input input-bordered flex items-center gap-2 rounded-2xl signup-height">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" /></svg>
                        <input type="text" className="grow" placeholder="Username or Email" value={username} onChange={(e) => setUsername(e.target.value)}/>
                    </label>
                    <label className="input input-bordered flex items-center gap-2 rounded-2xl signup-height">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
                        <input type="password" className="grow" placeholder="Password"  value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </label>

                    <button className="btn-style-1 text-hint2 sm:text-hint1 md:text-h5 lg:text-h4 signup-height" onClick={login} type="submit">Log in</button>
                    <button className="text-hint2 sm:text-hint1 md:text-h5 text-center text-purple-900">Forgot your password/username?</button>    
                </form>


                {/* sign in with google */}
                <div className="input input-bordered flex items-center gap-2 rounded-2xl w-5/6 signup-height">
                    <img src={iconGoogle} alt="log in with goole" className="h-4/6"></img>
                    <button className="grow text-hint2 sm:text-hint1 md:text-h5" onClick={loginGoogle}>Sign in with Google</button>
                </div>
                
                {/* switch to sign up */}
                <span className="signup-border-top w-5/6"></span>

                <button className="bg-gray-600 text-white input input-bordered rounded-2xl text-center font-semibold w-5/6 text-hint2 sm:text-hint1 md:text-h5 signup-height" onClick={toggleLogin}>Sign up for FREE Trial</button>

            </div>
        );
    }

    return ( 
        <div className="login-page w-full h-screen relative sm:min-h-[608px] font-content">

            <div className="absolute z-0 w-full h-full img-box">
                <img src={bg5} alt="pandas are looking into the sky" className="box-img"></img>
            </div>

            {showLogin ? renderSignIn() : renderSignup()}

            <div className="absolute bottom-2 left-0 z-1 flex flex-row gap-4 text-hint2 sm:text-hint1 md:text-h6 sm:gap-24 w-screen justify-center">
                <div className="text-gray-900">Privacy Policy</div>
                <div className="text-gray-900">Terms of Use</div>
                <div className="text-gray-900">Contact Us</div>
            </div>

        </div>
     );
}

export default LoginPage;