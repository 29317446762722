import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function PrivacyPage() {
    return ( 
        <div className="privacy-page font-content response-font-size-content">
            <Header/>

            <div className="w-full bg-landing-1">
                <div className="w-full max-w-[1440px] mx-auto h-full relative response-page-padding-x response-page-padding-y flex flex-col gap-4">
                    <div className="w-full flex gap-8 items-center">
                        <span className="flex-grow h-0 border-t-2 border-gray-400 rounded-sm"></span>
                        <div className="text-center font-title response-font-size-title1 font-semibold">PRIVACY POLICY</div>
                        <span className="flex-grow h-0 border-t-2 border-gray-400 rounded-sm"></span>
                    </div>
                    <div className="response-font-size-note text-gray-700 w-full text-end">Last updated May 01, 2024</div>
                    
                    <div>This privacy notice for Pandarrativ ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ( "process") your information when you use our services ("Services"), such as when you: Visit our website at https://pandarrativ.com , or any website of ours that links to this privacy notice. Engage with us in other related ways, including any sales, marketing, or events</div>
                    <div><b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at pandarrativ@gmail.com.</div>

                    {/* 1 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>

                    {/* 2 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>


                    {/* 3 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>


                    {/* 4 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>



                    {/* 5 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>



                    {/* 6 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>


                    {/* 6 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>


                    {/* 7 */}
                    <div className="text-center font-title response-font-size-title2 font-semibold mt-8">PRIVACY </div>
                    <div></div>




                </div>
            </div>



            <Footer/>
        </div>
     );
}

export default PrivacyPage;