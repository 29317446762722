
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import tempBG from "../../assets/imgs/narrative-transportation.jpg";

function PhilosophyPage() {
    return ( 
        <div className="philosophy-page font-content response-font-size-content">
            <Header/>

            <div className="w-full bg-landing-0">
                <div className="w-full max-w-[1440px] mx-auto flex flex-col gap-2 sm:gap-4 md:gap-8 pt-8 p-4 md:p-8">
                    <div className="font-title response-font-size-title1 font-semibold w-full text-center">Narrative Transportation</div>
                    <div className="w-1/2 aspect-video"><img src={tempBG} alt="temp" className="w-full h-full"></img> </div>
                    <div className="font-title response-font-size-title2 font-semibold">Narrative</div>
                    <div>The philosophy of narrative explores how stories shape identity, reality, ethics, and cognition, questioning narrative's nature, truth, and impact on human understanding and cultural practices.</div>

                    <div className="font-title response-font-size-title2 font-semibold">Therpay</div>
                    <div>Narrative therapy is a therapeutic approach that utilizes storytelling to help individuals reframe and understand their experiences, empowering them to reshape their identities and overcome challenges.</div>
                </div>
             </div>

            <div className="w-full bg-landing-1">
                <div className="w-full max-w-[1440px] mx-auto flex flex-col gap-2 sm:gap-4 md:gap-8 pt-8 p-4 md:p-8">
                    <div className="font-title response-font-size-title2 font-semibold">Applied Behavior Analysis (ABA)</div>
                    <div>Applied Behavior Analysis (ABA) is a scientific discipline that applies empirical approaches based on behavioral principles to modify behavior. It's particularly noted for its effectiveness in treating Autism Spectrum Disorder (ASD). ABA involves the application of techniques and principles to bring about meaningful and positive changes in behavior, focusing on the measurable aspects of behavior and the environment's influence on behavior.</div>
                    <div>Imagine a world where every child has the opportunity to reach their full potential. ABA steps into this world with a toolbox filled with strategies like reinforcement, task analysis, and behavior chaining. By understanding and modifying the environment and interactions, ABA therapists create a bridge for children with ASD to connect more effectively with the world around them, enhancing their ability to communicate, socialize, and learn.</div>

                </div>
            </div>


            <div className="w-full bg-landing-0">
                <div className="w-full max-w-[1440px] mx-auto flex flex-col gap-2 sm:gap-4 md:gap-8 pt-8 p-4 md:p-8">
                    <div className="font-title response-font-size-title2 font-semibold">Cognitive Behavior Therapy (CBT)</div>
                    <div>Cognitive Behavior Therapy (CBT) is a widely used, evidence-based treatment that focuses on altering dysfunctional emotions, behaviors, and thoughts by interrogating and uprooting negative or irrational beliefs. It is effective for a variety of conditions, including depression, anxiety disorders, marital problems, and severe mental illness.</div>
                    <div>Picture a person trapped in a maze of their own thoughts, where every turn leads to another dead end of negative beliefs and feelings. CBT serves as a guide, helping this person find the way out by challenging these negative patterns, teaching them to recognize their distortions in thinking, and providing new strategies to react to their environment. Through sessions that resemble a collaborative effort between therapist and client, individuals learn to construct a healthier perspective and develop coping mechanisms that enable them to face life's challenges head-on.</div>
                </div>
            </div>

            <div className="w-full bg-landing-1">
                <div className="w-full max-w-[1440px] mx-auto flex flex-col gap-2 sm:gap-4 md:gap-8 pt-8 p-4 md:p-8">
                    <div className="font-title response-font-size-title2 font-semibold">Dialectical Behavior Therapy (DBT)</div>
                    <div>Dialectical Behavior Therapy (DBT) is a form of cognitive-behavioral therapy that emphasizes the psychosocial aspects of treatment. It's specifically designed to help people who experience intense emotions. DBT is effective for treating a range of disorders, including borderline personality disorder, eating disorders, and substance abuse. It combines standard CBT techniques for emotion regulation and reality-testing with concepts of distress tolerance, acceptance, and mindful awareness largely derived from Buddhist meditative practice.</div>
                    <div>Imagine navigating a stormy sea of overwhelming emotions, where each wave threatens to overturn the boat of your mental stability. DBT is like a lighthouse, offering strategies that guide individuals through the turbulent waters. By learning mindfulness, distress tolerance, emotion regulation, and interpersonal effectiveness, individuals are equipped with a compass to manage their emotions and relationships more effectively, finding a path to calmer waters.</div>
                </div>
            </div>


            <Footer/>
        </div>
     );
}

export default PhilosophyPage;