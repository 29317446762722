import LandingSearchingGallery from "../../components/LandingSearchingGallery/LandingSearchingGallery";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./materials-page.css";
import pandara from "../../assets/icons/pandara-full.svg";

// temp
import temp1 from "../../assets/temp/social story-asking for permission.png";
import temp2 from "../../assets/temp/social story-attending religious or cultural events.png";
import temp3 from "../../assets/temp/social story-going to the movies or theater.png";
import temp4 from "../../assets/temp/social story-handling money transactions.png";
import temp5 from "../../assets/temp/social story-responding to emergencies.png";
import temp6 from "../../assets/temp/social story-understanding personal space.png";


const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
    { name: 'Price: Low to High', href: '#', current: false },
    { name: 'Price: High to Low', href: '#', current: false },
]
const popularLinks = [
    // { name: 'Asteroid Subtraction', href: '#' },
    // { name: 'Unicycle Race Measurement', href: '#' },
    // { name: 'Roly Workout', href: '#' },
    // { name: 'Bar Graphing with Eggs', href: '#' },
]
const filters = [
    {
        id: 'Printable Resource Type',
        name: 'Printable Resource Type',
        options: [
          { value: 'Social Story', label: 'Social Story', checked: false },
          // { value: 'Story Card', label: 'Story Card', checked: false },
          // { value: 'Flashcard', label: 'Flashcard', checked: false },
        ],
    },
  //   {
  //     id: 'Interactive Resource Type',
  //     name: 'Interactive Resource Type',
  //     options: [
  //       { value: 'Interactive Game Stories', label: 'Interactive Game Stories', checked: false },
  //       { value: 'Animation Stories', label: 'Animation Stories', checked: false },
  //     ],
  // },
    {
      id: 'Grade',
      name: 'Grade',
      options: [
        { value: 'Preschool', label: 'Preschool', checked: false },
        { value: 'Kindergaten', label: 'Kindergaten', checked: false },
        { value: '1st Grade', label: '1st Grade', checked: false },
        { value: '2nd Grade', label: '2nd Grade', checked: false },
        { value: '3rd Grade', label: '3rd Grade', checked: false },
        { value: '4th Grade', label: '4th Grade', checked: false },
        { value: '5th Grade', label: '5th Grade', checked: false },
        { value: '6th Grade', label: '6th Grade', checked: false },
      ],
    },
    {
      id: 'BESSI',
      name: 'BESSI Learning Goal',
      options: [
        { value: 'Self-Management Skills', label: 'Self-Management Skills', checked: false },
        { value: 'Social Engagement Skills', label: 'Social Engagement Skills', checked: false },
        { value: 'Cooperation Skills', label: 'Cooperation Skills', checked: false },
        { value: 'Emotional Resilience Skills', label: 'Emotional Resilience Skills', checked: false },
        { value: 'Innovation Skills', label: 'Innovation Skills', checked: false },
      ],
    },
    // {
    //   id: 'Language Learning GoaL',
    //   name: 'Language  Learning Goal',
    //   options: [
    //     { value: 'Vocabulary', label: 'Vocabulary', checked: false },
    //     { value: 'Speaking Fluency', label: 'Speaking Fluency', checked: false },
    //     { value: 'Storytelling', label: 'Storytelling', checked: false },
    //     { value: 'Cooperation Skills', label: 'Cooperation Skills', checked: false },
    //     { value: 'Conversation', label: 'Conversation', checked: false },
    //   ],
    // },
    // {
    //   id: 'Therapy Methodology',
    //   name: 'Therapy Methodology',
    //   options: [
    //     { value: 'ABA', label: 'ABA', checked: false },
    //     { value: 'CBT', label: 'CBT', checked: false },
    //     { value: 'DBT', label: 'DBT', checked: false },
    //   ],
    // },
]

const cardData = [
  {id:1 ,title:"Organizing personal spaces and objects", description:"Practice Cooperation Skills by Creating friends through hopscotch game.", img:temp1, href:"/material-content?id=case_cooperation", alt:"learning materials"},
  {id:2 ,title:"Regulating anger and irritation", description:"Emotional Resilience Skills: Building friendships through small steps.", img:temp2, href:"/material-content?id=case_emotional_resilience", alt:"learning materials"},
  {id:3 ,title:"Making friends through shared drawing.", description:"Social Engagement Skills - Persuasive Skill: Presenting arguments effectively", img:temp3, href:"/material-content?id=case_social_engagement", alt:"learning materials"},
 

]


function MaterialsPage() {
    const cards = cardData.map((item) => {
      return (
        <div className="col-span-1 shadow-card rounded-lg flex-grow-0">
          <figure><img src={item.img} alt={item.alt} className="rounded-t-lg" /></figure>
          <div className="flex flex-col gap-2 p-4 ">
            <a className="text-hint2 sm:text-hint1 md:text-h5 font-title font-semibold card-ellipsis" href={item.href}>{item.title}</a>
            <span className="flex-grow text-hint2 sm:text-hint1 md:text-content card-ellipsis">{item.description}</span>
            <div className="card-actions justify-end">
              <button className="card-hover-bg">
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
                </svg> */}

                {/* if favorite */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f77f00" className="w-6 h-6">
                  <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )
    })

    return ( 
        <div className="materials-page font-content">
            {/* header */}
            <Header/>

            {/* section 1 */}
            <div className="w-full">
                <div className="w-full max-w-[1440px] mx-auto">
                    <LandingSearchingGallery
                        title="Printable Materials"
                        sortOptions={sortOptions}
                        popularLinks={popularLinks}
                        filters={filters}
                        cards={cards}
                    ></LandingSearchingGallery>
                </div>
            </div>


            {/* section 2 */}
            {/* <div className="w-full bg-landing-1">
              <div className="w-full max-w-[1440px] mx-auto flex flex-row response-page-padding-x">
                <div className="hidden lg:flex items-end w-1/4 py-8">
                  <img src={pandara} alt="pandara is wearing a cape" className="w-full"></img>
                </div>

                <div className="flex flex-col lg:ml-10 gap-8 lg:pl-8 pt-16 pb-8 w-full">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="font-title response-font-size-title2 font-semibold">Free Worksheets and Printables for Kids</div>
                    <div className="w-full response-font-size-content">
                      Whether your child needs alttle math boostori intereste inlearning more about the solarsystem, ourfee worksheets and printable activities coverall the educational bases.Each workshet was createdby a profesional educator, so you know your child ilearncritical age-appropriate facts and concepts. Best ofall, many worksheets across a variety of subjects feature vibrant colors, cute characters, and interesting story prompts, so kids get excited about their learning adventure.
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 w-full response-font-size-content">
                    <div className="font-title response-font-size-title2 font-semibold">Make Learning Fun with Printable Worksheets</div>
                    <div className="w-full mt-2 response-font-size-content">
                      Parents understand that the best way to improve their cild's academic performance is to practice key learning skll Unfortunately, they also understand that mot kids resist these practice sessions because they find them tedious. That's where our printable worksheets come in handy. Not only do these worksheets help boost academicproficiency in all subjects acrossall grade levels, but theyre designed to make the entire learning experience more enjoyable for young minds.
                    </div>
                    <div className="w-full mt-2">
                      Forinstance your lttle one willbe more inlined to practice the alphabet when you print out our alphabet dot-to-dot workshet, which encourages young students to connect letters in sequence to build a doghouse around a cuddly dog character. Likewie learning about sounds becomes much more fun with our Time to Rhyme worksheet,whichwas professionally designed with recognizable objects and symbols that help kids match such rhyming words as clock and sock, and snake and cake.
                    </div>
                    <div className="w-full mt-2">
                      Older science students willeagerly explore the earths layers thanks to a worksheet that uses bright colors to identify and define the crust, mantle and core, while our African American Inventors worksheet uses easy to understand informational text blocks and vintage photographs to engage young history buffs.
                    </div>
                    <div className="w-full mt-2">
                      Because all of our contentis regularly updated wit fresh concepts and designs, you will never lack forways to keep students of all ages excited about learning.
                    </div>
                  </div>
                </div>

              </div>
            </div> */}

            {/* footer */}
            <Footer/>
        </div>
     );
}

export default MaterialsPage;