import "./about-us.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutUs from "../../assets/imgs/about-us.png";
import IconStart from "../../assets/icons/flying-star.svg";
import IconBroom from "../../assets/icons/broom.svg";

function AboutUsPage() {
    return ( 
        <div className="about-us-page font-content response-font-size-content">
            <Header/>

            {/* section 1 */}
            <div className="w-full bg-aboutus-1">
                <div className="w-full max-w-[1440px] response-page-padding-x pt-12 md:pt-24 pb-6 md:pb-12 mx-auto">
                    <div className="w-full sm:w-2/3 md:w-1/2 flex flex-col gap-8 h-full my-auto items-start">
                        <div className="font-title response-font-size-title1 font-semibold about-us-title-boarder">What is Pandarrativ and What is the Mission of it?</div>
                        <div className="w-full mt-4">
                            Our mission is to address the challenges of social-emotional-behavioral learning and inclusivity in language education through the transformative power of narratives. We believe generative narratives can have on learning experiences, making them more engaging, empathetic, and effective.
                        </div>
                        <button className="white-boarder-btn">Contact Us</button>
                    </div>
                   

                </div>
            </div>

            {/* section 2 */}
            <div className="w-full">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-8 lg:gap-12  w-full max-w-[1440px] response-page-padding-x-lg pt-12 md:pt-24 pb-12 md:pb-12 mx-auto">
                    <div className="rounded-lg img-box max-h-[240px] sm:max-h-[480px] md:max-h-[520px] lg:max-h-[1000px]">
                        <img src={AboutUs} alt="a unicorn" className="w-full box-img"></img>
                    </div> 

                    <div className="flex flex-col gap-2 sm:gap-4 md:gap-6 lg:gap-8">
                        <div className="font-title response-font-size-title2 font-semibold">
                            About Us | Pandarrative
                        </div>
                        
                        <div>We are an interdisciplinary, globally-⁠diverse team of engineers, designers, creatives, educators, ABA therapists, and storytellers who share a drive to create products for using narrative AI for non-cognitive skills learning. Curiosity shapes our team.</div>
                        <div className="flex flex-row gap-2 md:gap-4 lg:gap-8 w-full">
                            <button className="btn w-1/2 about-us-btn-1">Products</button>
                            <button className="btn w-1/2 about-us-btn-1">Our Teams</button>
                        </div>
                        <span className="w-full about-us-bar"></span>
                        <div>Pandarrativ's team comprises over 20 members, with researchers from the University of Illinois Urbana-Champaign, members from Northestern University, and former employees of several Fortune 500 high-tech companies. The team has extensive experience in the field of narrative, non-cognitive skills learning, and ABA methods, with many publications in top international conferences such as CHI and UIST. Their research and development members account for more than 60% of the total number of members. 
                            Our project details can be found at <a href="https://github.com/pandarrativ" className="link-color">Github Repository</a>.
                        </div>
                        <div><button className="btn about-us-btn-2">Learn More</button></div>
                    </div>

                </div>
            </div>


            {/* section 3 */}
            <div className="w-full bg-aboutus-2">
                <div className="w-full max-w-[1440px] response-page-padding-x-lg py-8 sm:py-12 mx-auto flex flex-col sm:flex-row gap-4 md:gap-6 lg:gap-8">
                    <div className="w-full sm:w-1/2 flex flex-col gap-2 sm:gap-4 md:gap-6 lg:gap-8 items-center p-2 sm:p-4 md:p-6 lg;p-8 border-2 border-white rounded-lg">
                        <img src={IconStart} alt="a flying star" className="aspect-square w-8 sm:w-1o md:w-12 lg:w-16"></img>
                        <div className="text-center text-white"> We care about the children and hope to be patient with all of them.</div>
                    </div>

                    <div className="w-full sm:w-1/2 flex flex-col gap-2 sm:gap-4 md:gap-6 lg:gap-8 items-center p-2 sm:p-4 md:p-6 lg;p-8 border-2 border-white rounded-lg">
                        <img src={IconBroom} alt="a broom" className="w-8 sm:w-1o md:w-12 lg:w-16 aspect-square"></img>
                        <div className="text-center text-white">We believe teachers are irreplaceable, and we aim to support their crucial role in fostering SEB skills learning without adding more burdens.</div>
                    </div>


                </div>
            </div>


            <Footer/>
        </div>
     );
}

export default AboutUsPage;