import { useEffect, useState } from "react";
import "./basic-pagination.css";


function BasicPagination({totalPages, handleOnClick}) {
    const [index, setIndex] = useState(1);
    const [showingIndices, setShowingIndices] = useState([]);

    useEffect(() => {
        // left part
        let list = [];
        if(index <= 4){
            for(let i=1; i<index; i++){
                list.push({page:i, content:i, enable:true});
            }
        }else{
            list.push({page:1, content:1, enable:true});
            list.push({page:0, content:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg>
                , enable:false});
            list.push({page:index-1, content:index-1, enable:true});
        }
        // cur
        list.push({page:index, content:index, enable:true});
        //right
        if(index >= totalPages - 3){
            for(let i=index+1; i<=totalPages; i++){
                list.push({page:i, content:i, enable:true});
            }
        }else{
            list.push({page:index+1, content:index+1, enable:true});
            list.push({page:0, content:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg>
                , enable:false});
            list.push({page:totalPages, content:totalPages, enable:true});
        }

        setShowingIndices(list)
    }, [index, totalPages])

    const setPage = (num) => {
        setIndex(num);
        handleOnClick(num);
    }

    return (  
       <div className="sibliing-pagination flex flex-row gap-6">

            <button disabled={index === 1} onClick={() => setPage(index - 1)} className={`sibliing-pagination-btn ${index === 1 ? "pagination-btn-disable": ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
            </button>

            {
                showingIndices.map((item, i) => {
                    return <button 
                            disabled={!item.enable} 
                            key={i} 
                            onClick={() => setPage(item.page)} 
                            className={`${item.enable ? "sibliing-pagination-btn": ""} ${index === item.page ? "pagination-btn-activate": ""}`}
                        >{item.content}</button>
                })
            }



            <button disabled={index === totalPages} onClick={() => setPage(index + 1)}  className={`sibliing-pagination-btn ${index === totalPages ? "pagination-btn-disable": ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </button>

       </div> 
    );
}

export default BasicPagination;