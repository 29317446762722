import "./landing-page.css";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";


import bg1 from "../../assets/imgs/bg-1.png";
import bg3 from "../../assets/imgs/bg-3.png";
import iconHouse from "../../assets/imgs/landing-house.png";
import iconStudent from "../../assets/icons/landing-student.svg";
import iconTeacher from "../../assets/icons/landing-teacher.svg";
import pandara from "../../assets/icons/pandara-full.svg";
import iconSection4 from "../../assets/imgs/landing-nara.png"

import imgSection2 from "../../assets/imgs/landing-2.png"
import iconStar8 from "../../assets/icons/star-8.svg";
import iconStar4 from "../../assets/icons/star-4.svg";
import iconHeartBlue from "../../assets/icons/heart-blue.svg";
import iconHeartGreen from "../../assets/icons/heart-green.svg";
import iconHeartPurple from "../../assets/icons/heart-purple.svg";
import iconHeartRed from "../../assets/icons/heart-red.svg";
import iconHeartYellow from "../../assets/icons/heart-yellow.svg";



function LandingPage() {


    return ( 
        <div className="landing-pag font-content">
            {/* 0.header */}
            <Header/>

            {/* section 1 */}
            <div className="w-full relative landing-section min-h-[410px]">
                <div className="absolute top-0 left-0 z-[-1] w-full h-full img-box bg-section-1"><img src={bg1} className="object-fill max-w-[1440px]" alt="a castle of pandas"></img></div>
                
                <div className="h-full w-full z-1 pt-4 max-w-[1440px] mx-auto flex flex-col items-center justify-center aspect-video z-1">
                    <div className="landing-section-1-heading text-h4 sm:text-h3 md:text-h2 lg:text-h0 w-1/2 flex flex-row justify-between font-title font-bold">
                        <span>P</span>
                        <span>a</span>
                        <span>n</span>
                        <span>d</span>
                        <span>a</span>
                        <span>r</span>
                        <span>r</span>
                        <span>a</span>
                        <span>t</span>
                        <span>i</span>
                        <span>v</span>
                    </div>
                    <div className="space h-1/6 w-full"></div>
                    <div className="landing-section-1-content w-full px-4 sm:px-8 md:px-16 flex flex-col sm:flex-row items-center justify-around translate-y-[-5%] sm:translate-y-[-30%] md:gap-8 lg:p-4">

                        {/* text-center */}
                        <div className="landing-section-1-title w-full flex flex-col gap-2 lg:w-1/3 sm:pr-4">
                            <div className="font-title font-semibold response-font-size-title1">
                                Shaping Social, Emotional, and Behavioral Skills Skills Through Narrative AI
                            </div>
                            <div className="response-font-size-content">
                                Unleash the power of storytelling in the garden of the mind, where each narrative weaves a unique tapestry of virtues. Embark on a journey that enriches the educational landscape, nurturing both the heart and the intellect.
                            </div>
                        </div>

                        <div className="hidden w-1/3 speech-bubble-1 md:flex flex-col items-center gap-2 p-2 ">
                            <div className="text-hint1 md:text-h5 lg:text-h4 font-semibold font-title">Our Products</div>
                            <div className="w-full flex flex-row justify-around">
                                <button className="scaling-5 ">
                                    <img src={iconStudent} alt="a student logo" className="w-20 lg:w-24"></img>
                                    <span className="text-hint2 lg:text-h5 w-24 lg:w-28">For Students</span>
                                </button>
    
                                <button className="scaling-5">
                                    <img src={iconTeacher} alt="a teacher logo"  className="w-20  lg:w-24"></img>
                                    <span className="text-hint2 lg:text-h5 w-24 lg:w-28">For Teachers</span>
                                </button>
                            </div>
                        </div>

                        <div className="md:hidden speech-bubble-1 py-2 px-8 mt-8 ml-[-10%] sm:ml-0">
                            <div className="flex flex-row gap-4 justify-between w-full">
                                <button className="scaling-5">
                                    <img src={iconStudent} alt="a student logo"  className="w-16"></img>
                                    <span className="text-hint2 w-20">Students</span>
                                </button>
           
                                <button className="scaling-5">
                                    <img src={iconTeacher} alt="a teacher logo" className="w-16"></img>
                                    <span className="text-hint2 w-20">Teachers</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-2/3 md:w-full aspect-[32/11] sm:h-1/3 absolute bottom-0 left-[16%] md:left-0 z-[-1]">
                    <img src={iconHouse} alt="a house on a story bood" className="h-full mx-auto"></img>
                </div>
            </div>

            {/* section2 */}
            <div className="w-full relative bg-landing-section-2 response-font-size-content py-4">
                <div className="w-full max-w-[1440px] mx-auto response-page-padding-x-lg response-page-padding-y flex flex-col gap-8 md:gap-12 lg:gap-16 ">
                    <div className="w-full flex flex-col gap-4 sm:flex-row sm:gap-12 lg:gap-16 items-center">
                        <div className="sm:hidden w-full relative aspect-square"></div>
                        
                        <div className="w-full sm:w-1/2 flex flex-col gap-4 md:gap-8">
                            <div className="font-title response-font-size-title1 font-semibold">Behavioral, Emotional, and Social Skills Inventory (BESSI)</div>
                            <div>The Behavioral, Emotional, and Social Skills Inventory (BESSI) is a comprehensive, reliable, valid, and efficient measure of social, emotional, and behavioral skills (SEB skills). It measures five major skill domains, which quickly summarize someone’s skills: Self-Management Skills, Social Engagement Skills, Cooperation Skills, Emotional Resilience Skills, and Innovation Skills. Please read more about BESSI from their website: 
                                <a href="http://www.sebskills.com/the-bessi.html" className="inline-link">  http://www.sebskills.com/the-bessi.html    
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#7d4da7" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                    </svg>
                                </a>
                            </div>
                 

                            <div><a className="btn landing-section2-btn" href="http://www.sebskills.com/">Read More</a></div>
                        </div>

                        <div className="hidden sm:block w-full sm:w-1/2 relative aspect-square">
                            <img src={iconStar8} className="w-[15%] absolute left-[8%] top-[8%]" alt="a star with eight lines"></img>
                            <img src={iconStar4} className="w-[24%] absolute bottom-[3%] right-[3%]" alt="a star with four lines"></img>
                            <div className="w-full h-full rounded-inf p-8 border-landing-section2"><img src={imgSection2} alt="kids are laughing"></img></div>
                        </div>
                    </div>
                    
                    <div className="w-full flex flex-wrap gap-4 justify-between">
                        {/* stars */}
                        <div className="landing-section2-card w-[155px] sm:w-[185px] md:w-[210px]">
                            <img src={iconHeartPurple} alt="a purple heart with padding on its side" className="w-8 md:w-12 lg:w-12"></img>
                            <div className="response-font-size-note">Self-Management</div>
                        </div>

                        <div className="landing-section2-card w-[155px] sm:w-[185px] md:w-[210px]">
                            <img src={iconHeartYellow} alt="a yellow heart with padding on its side" className="w-8 md:w-12 lg:w-12"></img>
                            <div className="response-font-size-note">Social Engagement</div>
                        </div>


                        <div className="landing-section2-card w-[155px] sm:w-[185px] md:w-[210px]">
                            <img src={iconHeartGreen} alt="a green heart with padding on its side" className="w-8 md:w-12 lg:w-12"></img>
                            <div className="response-font-size-note">Cooperation</div>
                        </div>


                        <div className="landing-section2-card w-[155px] sm:w-[185px] md:w-[210px]">
                            <img src={iconHeartRed} alt="a red heart with padding on its side" className="w-8 md:w-12 lg:w-12"></img>
                            <div className="response-font-size-note">Emotional Resilience</div>
                        </div>

                        <div className="landing-section2-card w-[155px] sm:w-[185px] md:w-[210px]">
                            <img src={iconHeartBlue} alt="a blue heart with padding on its side" className="w-8 md:w-12 lg:w-12"></img>
                            <div className="response-font-size-note">Innovation</div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>

            {/* section4 */}
            <div className="w-full relative bg-landing-section-4 response-font-size-content py-4">
                <div className="w-full max-w-[1440px] mx-auto response-page-padding-x-lg response-page-padding-y flex flex-row gap-8 md:gap-12 lg:gap-16 relative">
                
                <div className="w-[45%] aspect-square overflow-hidden relative">
                    <img src={iconSection4} alt="a rainbow" className="absolute w-full top-[-20%] left-0"/>
                </div> 
                {/* <img src={iconSection4} alt="a rainbow" className="absolute h-full top-0 left-0"/> */}



                  <div className="w-[55%] flex flex-col gap-4 justify-center">
         
                            <div className="font-title response-font-size-title1 font-semibold">Narrative Transportation</div>
                            <div className="h-splitter-orange"></div>
                            <div>Narrative transportation is the immersive experience of being drawn into a story, where readers or viewers become deeply engaged with the plot and characters, often losing awareness of their surroundings. This powerful phenomenon can influence attitudes, beliefs, and behaviors, making it a vital tool for SEB education, and therapy. We hope to create educational  narratives that resonate on an emotional and cognitive level.
                                {/* <a href="http://www.sebskills.com/the-bessi.html" className="inline-link">  http://www.sebskills.com/the-bessi.html    
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#7d4da7" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                    </svg>
                                </a> */}
                            </div>
                 

                            <div><a className="btn landing-section2-btn" href="/">Try Product</a></div>
             

                  </div>
                </div>
            </div>


            {/* section 3 */}
            <div className="w-full relative bg-section-3">
                <div className="w-full max-w-[1440px] mx-auto relative landing-section ">
                    <div className="w-full h-full top-0 left-0 z-0 img-box"><img src={bg3} className="object-fill h-full" alt="a panda is raising a flower"></img></div>
                            
                     {/* shifting bubbles        */}

                    <div className="absolute w-[90%] md:w-7/12 left-[5%] bottom-[10%] z-[5] bg-floating-board px-6 md:px-12 lg:px-16 pt-8 py-12">
                        <div className="w-full flex justify-center items-center response-font-size-content">Introducing Sage Agent, an AI-driven companion designed to provide personalized support through evidence-based Applied Behavior Analysis (ABA) and Cognitive Behavioral Therapy (CBT) techniques.</div>
                    </div>
                    

                    {/* large screen */}
                    <div className="flex absolute top-0 left-0 z-1 landing-section-3-content w-full h-full flex-row items-center gap-0 justify-center">
                        <img src={pandara} alt="pandara is wearing a cape" className="h-4/6"></img>
                        <div className="h-3/6 aspect-square ">  
                            <div className="section-3-shadow scaling-5 hidden md:flex flex-col gap-2 sm:gap-4 items-center justify-center text-hint3 sm:text-h5 md:text-hd font-title font-semibold lg:text-h3">
                                <div className="flex flex-row gap-2 sm:gap-4 rotate-[-12deg]">
                                    <div className="rotate-[-10deg]">H</div>
                                    <div className="rotate-[10deg]">i</div>
                                    <div>,</div>
                                </div>
                                <div className="rotate-[-12deg] ">Pandara</div>
                            </div>
                            {/* <button className="hidden md:block absolute w-2/4 z-3" onClick={toggleBubblePandara}><img src={iconNext} alt="a arrow point to right"></img></button> */}
                        </div>
                    </div>
                    
                </div>
            </div>


            {/* footer */}
            <Footer/>
        </div>
     );
}

export default LandingPage;