import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from './pages/Loginpage/LoginPage';
import CredentialFetchPage from './pages/Loginpage/CredentialFetchPage';
import RoleSelectionPage from './pages/RoleSelectionPage/RoleSelectionPage';
import ProductPage from './pages/ProductsPage/ProductsPage';
import MaterialsPage from './pages/MaterialsPage/MaterialsPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import PhilosophyPage from './pages/PhilosophyPage/Philosophy';
import SafetyPage from './pages/SafetyPage/SafetyPage';
import SupportPage from './pages/SupportPage/SupportPage';
import PrivacyPage from './pages/Privacy/PrivacyPage';
import TermsPage from './pages/TermsOfUse/TermsPage'; 
import SocialStoryPage from './pages/SocialStoryPage/SocialStoryPage';
import MaterialContentPage from './pages/MaterialsPage/MaterialContentPage';

// mindmap system


//reframing system




export default function Routers(){  
    return (
        <BrowserRouter>
            <Routes>
                {/* landing page */}
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/credential" element={<CredentialFetchPage/>} />
                <Route path="/role-selection" element={<RoleSelectionPage/>}/>
                <Route path="/products" element={<ProductPage/>}/>
                <Route path="/materials" element={<MaterialsPage/>}/>
                {/* <Route path="/about-us" element={<AboutUsPage/>}/> */}
                <Route path="/philosophy" element={<PhilosophyPage/>} />
                <Route path="/safety" element={<SafetyPage/>} />
                <Route path="/support" element={<SupportPage/>} />

                <Route path="/privacy" element={<PrivacyPage/>} />
                <Route path="/terms" element={<TermsPage/>} />
                <Route path="/social-story" element={<SocialStoryPage/>} />
                <Route path="/material-content" element={<MaterialContentPage/>} />


            </Routes>
        </BrowserRouter>
    )
};