import "./support-page.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";


const cardInfo = [
    {title:"Digital Safety", description: "Safety is always the first. Utilizing the Consensus Game framework, the Generator produces narratives while the Discriminator evaluates them against safety criteria, leading to an equilibrium ensuring content accuracy and safety.", href:"/safety"},
]


function SupportPage() {
    function SupportCard(props){
        return (
            <div className="support-card p-8 shadow-shift-lb max-w-[500px] mx-auto">
                <div className="flex flex-col w-full gap-8">
                    <div className="response-font-size-title2 font-semibold">{props.item.title}</div>
                    <div>{props.item.description}</div>

                    <div className="flex flex-row w-full justify-end">
                        <a href={props.item.href} className="btn about-us-btn-2">See More</a>
                    </div>
                </div>
            </div>
        );
    }


    return ( 
        <div className="support-page font-content response-font-size-content">
            <Header/>

            <div className="w-full bg-support-hero">

                <div className="w-full max-w-[1440px] mx-auto h-full relative min-h-[640px] md:min-h-[720px] lg:max-h-[960px]">
                    <div className="absolute left-[5%] bottom-[10%] bg-floating-white px-12 sm:px-16 md:pr-20 lg:pr-28 pt-8 pb-12">
                        <div className="font-title font-semibold response-font-size-title1">Support</div>
                        <div>Welcome to Pandarrativ Support Center!</div>
                    </div>
                </div>
            </div>


            <div className="w-full bg-landing-1">
                <div className="w-full max-w-[1440px] mx-auto h-full relative response-page-padding-x response-page-padding-y">

                     <div className="w-full flex gap-8 items-center">
                        <span className="flex-grow h-0 border-t-2 border-gray-400 rounded-sm"></span>
                        <div className="text-center font-title response-font-size-title1 font-semibold">Have a Question? Look Here</div>
                        <span className="flex-grow h-0 border-t-2 border-gray-400 rounded-sm"></span>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 px-8 mt-12">
                       {cardInfo.map((item, i) => {
                            return <SupportCard key={i} item={item}/>
                        })}


                    </div>


                </div>
            </div>



            <Footer/>
        </div>
     );
}

export default SupportPage;