import './App.css';
import Routers from './router';
import axios from 'axios';

function App() {
  axios.defaults.withCredentials = true;

  return (
    <div className="App">
        <Routers></Routers>
    </div>
  );
}

export default App;
