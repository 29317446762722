import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./materials-page.css";
import { useSearchParams } from 'react-router-dom';
import { useEffect } from "react";


function MaterialContentPage() {
    const [searchParams] = useSearchParams();

    // Fetch a specific query parameter
    const paramValue = searchParams.get('id');
    useEffect(() => {
        console.log(paramValue);
    }, [])


    return (
    <div className="materials-page font-content">
        {/* header */}
        <Header/>

        {/* section 1 */}

        {/* <PDFViewer pdfURL="/case_cooperation.png"></PDFViewer> */}
        <div className="w-full bg-landing-section-2">
                <div className="w-full max-w-[1440px] mx-auto p-4 md:p-16 lg:px-32">
                    <img src={`/${paramValue}.png`} alt="example" class="w-[70%] mx-auto"></img>
                </div>
            </div>




    

        {/* footer */}
        <Footer/>
    </div>
);
}

export default MaterialContentPage;