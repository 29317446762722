import "./product-page.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import iconBubbleTeacher from "../../assets/icons/bubble-teacher.svg";
import iconBubbleParent from "../../assets/icons/bubble-parent.svg";
import iconBubbleChildren from "../../assets/icons/bubble-children.svg";
import iconBubbleLine from "../../assets/icons/bubble-line.svg";
import iconStudent from "../../assets/icons/landing-student.svg";
import iconParent from "../../assets/icons/landing-parent.svg";
import iconTeacher from "../../assets/icons/landing-teacher.svg";
import sysDemo1 from "../../assets/imgs/system-demo-1.png";
import sysDemo2 from "../../assets/imgs/system-demo-2.png";
import sysDemo3 from "../../assets/imgs/system-demo-3.png";
import sysDemo4 from "../../assets/imgs/system-demo-4.png";
import iconNext from "../../assets/icons/btn-next.svg";
import pandara from "../../assets/icons/pandara-full.svg";

import myVideo from '../../assets/videos/new_demo_edited_8rd - Made with Clipchamp.mp4';

function ProductPage() {
    return ( 
        <div className="product-page font-content">
            {/* header */}
            <Header/>

            {/* Section 1 */}
            <div className="w-full product-section bg-landing-1">
                <div className="w-full h-full max-w-[1440px] aspect-video mx-auto relative overflow-hidden">
                    {/* line */}
                    <div className="product-section-1-line">
                        <img src={iconBubbleLine} className="h-full w-full object-fill" alt="a line go through bubbles"></img>
                    </div>

                    {/* teacher */}
                    <div className="absolute w-1/3 h-1/2 z-1 left-[2%] top-[35%]">
                        <img src={iconBubbleTeacher} alt="a bubble for teacher materials" className="absolute w-2/3 top-0 right-0 z-2 scaling-5 hover:cursor-pointer"></img>
                        <img src={iconTeacher} alt="a teacher reading a book" className="absolute left-[0] top-[45%] z-2 w-1/3"></img>
                        <span className="response-font-size-title2 font-title absolute top-[-13%] left-[10%] lg:top-[-7%] font-semibold color-product-teachers">Explore</span>
                    </div>

                    {/* parent */}
                    <div className="absolute w-1/3 h-1/2 z-1 left-[30%] top-[42%]">
                        <img src={iconBubbleParent} alt="a bubble for parent guidance" className="absolute w-2/3 top-0 right-0 z-2 scaling-5 hover:cursor-pointer"></img>
                        <img src={iconParent} alt="a parent is smiling" className="absolute left-[5%] top-[40%] z-2 w-1/3"></img>
                        <span className="response-font-size-title2 lg:top-[-20%] font-title absolute top-[-15%] left-[40%] font-semibold color-product-parents">Deliver</span>
                    </div>

                    {/* Children */}
                    <div className="absolute w-1/3 h-1/2 z-1 left-[60%] top-[20%]">
                        <img src={iconBubbleChildren} alt="a bubble for parent guidance" className="absolute w-2/3 top-0 left-0 z-2 scaling-5 hover:cursor-pointer"></img>
                        <img src={iconStudent} alt="a parent is smiling" className="absolute right-[5%] top-[43%] z-2 w-1/3"></img>
                        <span className="response-font-size-title2 font-title absolute top-[5%] md:top-[10%] right-[5%] font-semibold color-product-children">Build</span>
                    </div>
                </div>
            </div>


            {/* Section 2 */}
            <div className="w-full product-section-teachers bg-landing-4 text-center py-4 md:py-8 response-font-size-title1 font-title font-semibold">
                <div className="w-full mx-auto max-w-[1440px]">
            ErisedStory: LLM-Simulated Mentor With Personality Profiles in Human-AI Interactive Storytelling for Social, Emotional, and Behavioral Skills Training
            </div>
            </div>

            <div className="w-full product-section-teachers bg-landing-2 relative">
                <div className="w-full h-full max-w-[1440px] aspect-video mx-auto relative p-4 md:p-16 lg:px-32">
                    <video width="w-[70%]" controls>
                        <source src={myVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                {/* <div className="hidden md:block  absolute left-[13%] bottom-[5%] h-2/6 aspect-square z-2">  
                        <div className="section-3-shadow scaling-5 flex flex-col items-center justify-center gap-2 font-title font-semibold response-font-size-title2">
                            <div className="flex flex-row gap-1 md:gap-4 rotate-[-12deg]">
                                <div className="rotate-[-10deg]">S</div>
                                <div className="rotate-[-10deg]">t</div>
                                <div className="rotate-[-10deg]">a</div>
                                <div className="rotate-[-10deg]">r</div>
                                <div className="rotate-[-10deg]">t</div>
                            </div>
                        </div>
                        <button className="absolute w-2/3 z-2 right-[-22%] bottom-[-5%]"><img src={iconNext} alt="a arrow point to right"></img></button>
                    </div> */}
            </div>

            {/* <div className="w-full product-section-teachers bg-landing-3">
                <div className="w-full h-full max-w-[1440px] aspect-video mx-auto relative">
                    <div className="absolute w-[45%] h-[28%] lg:w-[45%] right-[3%] top-[3%] flex flex-col justify-between">
                        <div className="w-full response-font-size-title2 font-title font-semibold lg:leading-7">
                            Customize story cards for children to learn SEB skills!
                        </div>
                        <div className="w-full hidden md:block text-hint2 lg:text-h5 lg:leading-7">
                            By creating and revising stories based on provided illustrations and prompts, children learn to express adaptive attitudes and behaviors, fostering transferable skills for real-life situations.
                        </div>
                    </div>
                                 
                    <img src={sysDemo2} alt="system demo of pandarrative for users to create SEB related materials" className="absolute w-10/12 bottom-[5%] left-[3%] z-1 shaking"></img>

                    <div className="hidden md:block  absolute left-[13%] bottom-[5%] h-2/6 aspect-square z-2">  
                        <div className="section-3-shadow scaling-5 flex flex-col items-center justify-center gap-2 font-title font-semibold response-font-size-title2">
                            <div className="flex flex-row gap-1 md:gap-4 rotate-[-12deg]">
                                <div className="rotate-[-10deg]">S</div>
                                <div className="rotate-[-10deg]">t</div>
                                <div className="rotate-[-10deg]">a</div>
                                <div className="rotate-[-10deg]">r</div>
                                <div className="rotate-[-10deg]">t</div>
                            </div>
                        </div>
                        <button className="absolute w-2/3 z-2 right-[-22%] bottom-[-5%]"><img src={iconNext} alt="a arrow point to right"></img></button>
                    </div>
                </div>
            </div> */}


            {/* Footer */}
            <Footer/>
        </div>
     );
}

export default ProductPage;