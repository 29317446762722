const host = process.env.REACT_APP_BACKEND_HOST;


export const getSignUpDestRouter = (dest) => {
    return `${host}/auth/register?dest=${dest}`;
} 
export const getSignInDestRouter = (dest) => {
    return `${host}/auth/login?dest=${dest}`;
} 
export function authSignRedirectRouter (dest){
    return `${host}/auth/google?dest=${dest}`;
}



export const setRoleRouter = `${host}/auth/role-selection`;
export function authGoogleRedirectRouter (dest){
    return `${host}/auth/google?dest=${dest}`;
}


export const goolgeAuthRedirects = `${host}/auth/google/callback`;
export const oauthSessionInfoRouter = `${host}/auth/user-data`;